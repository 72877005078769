import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
Vue.config.productionTip = false;


import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import uk from 'uikit'
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js'
Vue.mixin({
  data: function () {
    return {
      get uk () {
        return uk
      },
    }
  },
});

new Vue({
  render: (h) => h(App),
}).$mount("#app");
