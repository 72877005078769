<template>
<div class="landing_wraps">
<div class="vid_wrap">
  <video src="~@/assets/video.mp4" playsinline muted loop autoplay></video>
</div>
<div class="container">
  <div class="wrap_text">
    <div class="title1">
      <h1>Velkommen til Trondheim Bilvask</h1>
    </div>
    <div class="main_logo">
      <img src="~@/assets/logo.png" alt="">
    </div>
    <div class="branch_wrap">
      <h2> Velg avdeling</h2>
      <div class="flex_br">
        <Tilt :options="option" class="tiltClass1">
          <a href="https://lade.trondheimbilvask.no" target="_blank">  <img src="~@/assets/b1.png" alt=""></a>
        </Tilt>
         <Tilt :options="option" class="tiltClass2">
          <a href="https://sirkus.trondheimbilvask.no" target="_blank">  <img src="~@/assets/b2.png" alt=""></a>
        </Tilt>
            
      </div>
    </div>
  </div>
  <div class="copy">
    <span>Copyright © 2023 trondheim bilvask | Powered by Remak As</span>
  </div>
</div>
</div>
</template>
<script>
import Tilt from "vanilla-tilt-vue";
export default {
  name: "Landing",
    components: { Tilt },
    data(){
      return{
          option: {
        glare: true,
        "max-glare": 0.5,
        scale: 1.1,
      },
      }
    }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
body,html{
  color: white;
}
.landing_wraps{
  text-align: center;
  width: 100%;
  height: 100vh;
  .vid_wrap{
    video{
      width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    z-index: 0;
    }
    &::after{
      content: '';
      background-color: white;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      opacity: 0.6;

    }
  }
  .wrap_text{
   position: relative;
    z-index: 2;
    align-items: center;
    justify-content: center;
     height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -50px;
    h1{
      font-family: 'Oswald', sans-serif;
     color: #106cb2;
     font-weight: 500;
     font-size: 30px;
     letter-spacing: 1px;
    }
     h2{
      font-family: 'Oswald', sans-serif;
     color: #000;
     font-weight: 500;
     font-size: 25px;
    letter-spacing: 1px;
    }
    .main_logo{
      text-align: center;
      img{
        width: 320px;
        height: auto;
        object-fit: contain;
        // 
      }
    }
    .branch_wrap{
       margin-top: 80px;
           width: 100%;
       .flex_br{
        margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
       .tiltClass1{
        margin-right: 10px;
         border-radius: 20px;
      }
      .tiltClass2{
        margin-left: 10px;
         border-radius: 20px;
      }
      img{
        width: 200px;
        height: 150px;
        object-fit: contain;
        // 
            background-image: linear-gradient(0deg, rgb(255 255 255 / 23%) 0%, rgb(255 255 255 / 86%) 100%);
    opacity: 0.8;
    padding: 20px;
    border-radius: 20px;
      }
    }
    }
  }
  .copy{
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
    color: black;
    font-size: 14px;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;

  }
}

@media(max-width:992px){
.landing_wraps .wrap_text h1{
  font-size: 24px;
}
.landing_wraps .wrap_text h2{
  font-size: 20px;
}
.landing_wraps .wrap_text .branch_wrap .flex_br img {
    width: 150px;
    height: 140px;
}
.landing_wraps .copy[data-v-0e6814cc] {
    position: absolute;
    bottom: 24px;
    font-size: 13px;
}
}
</style>

// Velg avdeling
// 
